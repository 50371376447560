import React from 'react';
import { Layout } from 'antd';

const { Footer} = Layout;

const Home = () => {
    return (
        <React.Fragment>
            <Footer style={{ textAlign: 'center' }}>Email Blaster ©2024 PT Sentral Mitra Informatika</Footer>
        </React.Fragment>
    );
}

export default Home;
