import React from 'react';
import { Affix, Layout, Menu, Button, Dropdown, message } from 'antd';
import {LogoutOutlined} from '@ant-design/icons'
import AppLogo from '../../logo.png'
import BpwSquared from '../../bpwsquared.png'
import {Link} from 'react-router-dom'

const { Header} = Layout;

const Home = () => {
    const isLoggedIn = localStorage.getItem('usertoken')
    const logOut = () =>{
        message.loading('Logging out',0)
        localStorage.removeItem('username')
        localStorage.removeItem('usertoken')
        setTimeout(() => {
            message.destroy()
            window.location.href="/login"
        }, 2000);
    }
    const profileBox = (
        isLoggedIn ? 
        <Menu>
            <Menu.Item key="0">
                <Button onClick={logOut} icon={<LogoutOutlined />} danger>Log out</Button>
            </Menu.Item>
        </Menu> : <div></div>
    )
    return (
        <React.Fragment>
            <Affix offsetTop={0}>
                <Header theme="light" className="header" style={{ padding: 0, backgroundColor:'#fff' }} >
                    <div className="app-logo">
                        <Link to='/'>
                            <img src={AppLogo} style={{height:'60px',paddingLeft:'20px'}} alt='PT Sentral Mitra Informatika'/>
                        </Link>
                    </div>
                    <Dropdown overlay={profileBox} trigger={['click']}>
                        <div style={{float:'right', paddingRight:'10px'}}>
                            <img src={BpwSquared} style={{height:'50px', borderRadius:'50%', border:'1px solid #bfbfbf'}} alt='user'/>
                        </div>
                    </Dropdown>
                </Header>
            </Affix>
        </React.Fragment>
    );
}

export default Home;
